import React from 'react';
import { CssBaseline } from '@mui/material';
import OrderPage from './pages/OrderPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Router>
        <Routes>
          <Route
            path='/redirecturl/:id'
            element={<OrderPage />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
