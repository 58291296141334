import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';

const Header: React.FC = () => {
  return (
    <AppBar position='static'>
      <Toolbar sx={{ backgroundColor: '#e9ddd7' }}>
        <img
          src='/adori_logo.png'
          alt='Logo'
          style={{ width: '50px', marginRight: '16px' }}
        />
        <Typography
          variant='h6'
          sx={{ color: '#6e584c' }}
        >
          Order Tracker
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
