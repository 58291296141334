import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@mui/material';
import Header from '../components/Header';
import PaymentStatus from '../components/PaymentStatus';
import { useParams } from 'react-router-dom';
import axios from 'axios';

interface Params {
  [key: string]: string | undefined;
}

interface OrderInfo {
  orderNumber: string;
  isPaymentSucceeded: boolean;
}

const OrderPage: React.FC = () => {
  const params = useParams<Params>();
  const [orderInfo, setOrderInfo] = useState<OrderInfo>();
  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    if (params.id) {
      const fetchById = async () => {
        try {
          const record = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}=${params.id}`
          );
          if (record?.data) {
            setLoader(false);
            setOrderInfo(record?.data);
          }
        } catch (error: any) {
          setLoader(false);
          console.error(error);
        }
      };
      fetchById();
    }
  }, [params.id]);

  return (
    <>
      <Header />
      <Container
        sx={{
          minHeight: `calc(100vh - 60px)`,
          maxWidth:"100% !important",
          backgroundColor: '#f8f4f2',
          overflow: 'hidden',
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{ marginTop: 3 }}
        >
          <Grid
            item
            xs={12}
          >
            <PaymentStatus
              {...orderInfo}
              loader={loader}
            />
          </Grid>
          {/* <Grid
            item
            xs={12}
          >
            <Button
              fullWidth
              size='large'
              variant='contained'
              sx={{ backgroundColor: '#b4917f' }}
            >
              Go Back
            </Button>
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
};

export default OrderPage;
