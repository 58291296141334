import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
} from '@mui/material';
import Label from './Label';
import { styled } from '@mui/material/styles';
import { FaCheckCircle } from 'react-icons/fa';
import { FaAddressBook } from 'react-icons/fa';
import { FaWallet } from 'react-icons/fa6';
import { FaShippingFast } from 'react-icons/fa';

interface OrderDetailsProps {
  orderNumber?: string | undefined;
  isPaymentSucceeded?: boolean | undefined;
  loader: boolean;
}
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#b4917f',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#b4917f',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#b4917f',
      fontWeight: 600,
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#b4917f',
      zIndex: 1,
      fontSize: 24,
    },
    '& .QontoStepIcon-circle': {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot
      ownerState={{ active }}
      className={className}
    >
      {completed ? (
        <FaCheckCircle className='QontoStepIcon-completedIcon' />
      ) : (
        <div className='QontoStepIcon-circle' />
      )}
    </QontoStepIconRoot>
  );
}

interface Steps {
  name: string;
  icon: any;
}

const steps: Steps[] = [
  {
    name: 'Shipping Address',
    icon: (
      <FaAddressBook
        style={{ height: '20px', width: '20px', color: '#808080' }}
      />
    ),
  },
  {
    name: 'Payment Details',
    icon: (
      <FaWallet style={{ height: '20px', width: '20px', color: '#808080' }} />
    ),
  },
  {
    name: 'Review your order',
    icon: (
      <FaShippingFast
        style={{ height: '20px', width: '20px', color: '#808080' }}
      />
    ),
  },
];

const PaymentStatus: React.FC<OrderDetailsProps> = ({
  orderNumber,
  isPaymentSucceeded,
  loader,
}) => {
  const checkStepFunction = (isPaymentSucceeded: boolean) => {
    if (isPaymentSucceeded) {
      return 3;
    }
  };

  return (
    <Card
      sx={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      }}
    >
      {loader ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '150px',
            }}
          >
            <CircularProgress
              sx={{
                color: '#b4917f',
              }}
            />
          </div>
        </>
      ) : (
        <CardContent>
          <Typography variant='h5'>Payment Status</Typography>
          <Typography
            variant='subtitle1'
            sx={{ mt: 1 }}
          >
            Status:{' '}
            <Label
              variant='ghost'
              color={isPaymentSucceeded ? 'success' : 'error'}
            >
              {isPaymentSucceeded ? 'Successful' : 'Failed'}
            </Label>
          </Typography>
          {isPaymentSucceeded && (
            <>
              <Box sx={{ width: '100%', px: 0.5, py: 3 }}>
                <Stepper
                  activeStep={checkStepFunction(isPaymentSucceeded)}
                  alternativeLabel
                  connector={<QontoConnector />}
                  orientation={
                    window?.innerWidth <= 300 ? 'vertical' : 'horizontal'
                  }
                >
                  {steps.map((label) => (
                    <Step key={label?.name}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='center'
                        >
                          <Box>{label.icon}</Box>
                          <Box
                            mt={1}
                            sx={{ fontWeight: 'bold' }}
                          >
                            {label.name}
                          </Box>
                        </Box>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              <Typography
                variant='h6'
                gutterBottom
                sx={{ mt: 1.5 }}
              >
                Thank you for your order!
              </Typography>
              <Typography variant='body2'>
                Your order number is <strong>#{orderNumber}</strong>. We have
                emailed your order confirmation and will update you once it's
                shipped.
              </Typography>
            </>
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default PaymentStatus;
